import React from "react";
import { Box, Grid, GridItem, useMediaQuery } from "@chakra-ui/react";

function Layout ({
    children
}) {

    const [ isLargerThan900 ] = useMediaQuery('(min-width: 900px)')

    return (
        <Grid
            templateAreas={isLargerThan900 ? `"header header" "main main" "footer footer"` : `"header header" "main main" "footer footer"`}
            gap='1' color='blackAlpha.700' fontWeight='bold'
        >
            <GridItem pl='2' bg='white.300' area={'header'} sx={{
                justifyItems: 'center',
                position: 'sticky',
                top: 0,
                zIndex: 999,
                background: 'white'
            }}>
                <Box sx={{
                    justifyContent: 'center',
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 2, paddingRight: 2
                }}>
                    Football Prediction <span style={{fontSize: 10}}>by Wuebuild</span>
                </Box>                
            </GridItem>
            <GridItem pl='2' pr='2' area={'main'}>
                <div style={{ height: '100vh' }}>
                    {children}
                </div>
            </GridItem>
            <GridItem pl='2' pr='2' bg='white.300' area={'footer'}>
                <Box sx={{
                    justifyContent: 'center',
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 2, paddingRight: 2
                }}>
                    <div style={{
                        fontSize: 12
                    }}>Copyright © {new Date().getFullYear()} Wuebuild</div>
                </Box>
            </GridItem>
        </Grid>
    )
}

export default Layout