import React, { useEffect, useState } from "react"
import { Grid, Img, SimpleGrid } from "@chakra-ui/react"
import { data } from "../../data/item"
import { useParams } from "react-router-dom"

function NewsDetail ({

}) {
    
    const [ newsData, setNewsData ] = useState(null)
    const { id } = useParams();

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].href === id) {
                console.log(data[i])
                setNewsData(data[i])
                break;
            }
        }
    }

    // <SimpleGrid columns={2} spacing={10}>
    // <Box bg='tomato' height='80px'></Box>
    // <Box bg='tomato' height='80px'></Box>
    // <Box bg='tomato' height='80px'></Box>
    // <Box bg='tomato' height='80px'></Box>
    // <Box bg='tomato' height='80px'></Box>
    // </SimpleGrid>

    return (
        <Grid p={2}>
            {
                newsData &&
                <SimpleGrid columns={2}>
                    <SimpleGrid>
                        <Grid pb={2} sx={{
                            fontSize: 32
                        }}>
                            {newsData.title}
                        </Grid>
                        <Grid>
                            <Img src={newsData.headlineImage} width={'auto'} height={300} />
                        </Grid>
                        <Grid pt={2} sx={{
                            fontSize: 12
                        }}>
                            {newsData.story}
                        </Grid>
                        {
                            newsData.section && newsData.section.map( value => {
                                return (
                                    <Grid pt={2} sx={{
                                        fontSize: 24
                                    }}>
                                        {value.title}
                                        {value.content && value.content.map( v => {
                                            return (
                                                <>
                                                    {
                                                        v.image &&       
                                                        <Grid>
                                                            <Img src={v.image} />
                                                        </Grid> 
                                                    }
                                                    {
                                                        v.story &&
                                                        <Grid pt={2} sx={{
                                                            fontSize: 12
                                                        }}>
                                                            <pre className="">{v.story}</pre>
                                                        </Grid>
                                                    }
                                                </>
                                            )
                                        })}
                                    </Grid>
                                )
                            })
                        }
                    </SimpleGrid>
                </SimpleGrid>
            }
            {
                !newsData &&
                <Grid textAlign={"center"}>
                    Not Found
                </Grid>
            }
        </Grid>
    )
}

export default NewsDetail