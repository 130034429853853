import React from "react";
import News from "../news/News";

import { SimpleGrid } from "@chakra-ui/react";
import { data } from "../../data/item";


function Home ({
    
}) {

    return (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={6}>
            {
                data.map( value => {
                    return ( <News data={value}/> )
                })
            }
            {/* <News data={data[0]}/>
            <News data={data[0]}/>
            <News data={data[0]}/>
            <News data={data[0]}/>
            <News data={data[0]}/>
            <News data={data[0]}/> */}
        </SimpleGrid>
    )
}

export default Home