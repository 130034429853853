import React from "react"
import { Card, CardBody, Grid, Img } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

function News ({
    data
}) {
    const navigate = useNavigate()

    return (
        <Grid style={{
            cursor: 'pointer'
        }} onClick={() => {
            navigate(`/prediction/${data.href}`)
        }}>
            <Card>
                <Img src={data.headlineImage} sx={{
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6
                }}/>
                <CardBody sx={{
                    p: 2
                }}>
                    <div style={{
                        fontSize: 14
                    }}>{data.title}</div>
                    <div style={{
                        fontSize: 12, fontWeight: 500
                    }}>{data.story}</div>
                    <Grid sx={{
                        p: 2
                    }} textAlign={"right"}>
                        <div>Read more ... </div>
                    </Grid>
                </CardBody>
            </Card>
        </Grid>
    )
}

export default News