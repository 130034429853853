import React from "react"

import Home from "../pages/home/Home"
import NewsDetail from "../pages/news/NewsDetail"

const routes = [
    {
        name: "Home",
        route: "/",
        component: <Home />,
    },
    {
        name: "Prediction",
        route: "/prediction/:id",
        component: <NewsDetail />,
    },
]

export default routes